import React from "react";
import { makeStyles } from "@mui/styles";
// import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';

const useStyles = makeStyles({
  dropzone: {
    minWidth: '300px !important',
    overflow: 'hidden !important'
  }
});

const FileUpload = ({field, index, form_data}) => {
  const classes = useStyles();

  const getUploadParams = ({ meta }) => {
    return {
      url: field.url.replace(':id', form_data.id),
    }
  }
  
  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }, status) => { 
    // console.log(status, meta, file)
    if(status === 'done') {
      // do something here
      // afterSave()
    } else if(status === 'error_upload') {
      alert('Please try again...')
    }
  }

  // receives array of files that are done uploading when submit button is clicked
  const handleSubmit = (files, allFiles) => {
    // console.log(files.map(f => f.meta))
    allFiles.forEach(f => f.remove())
  }

  return (
    <Dropzone
      className={classes.dropzone}
      styles={{dropzone: {minWidth: 500}}}
      getUploadParams={getUploadParams}
      onChangeStatus={handleChangeStatus}
      onSubmit={handleSubmit}
      accept="image/*"
      maxFiles={1}
      multiple={false}
      inputContent='Drag a photo here'
      SubmitButtonComponent={props => null}
    />
  );
}

export default FileUpload;
