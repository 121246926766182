import React from "react";
import { makeStyles } from "@mui/styles";
import TextField from '@mui/material/TextField';

const useStyles = makeStyles({
  fieldElement: {
    margin: "10px 0 10px 0 !important",
  },
});

const TextInput = ({field, index, form_data, handleFieldChange, is_submitting}) => {
  const classes = useStyles();

  return (
    <TextField
      autoFocus={index === 0 ? true : false}
      margin="dense"
      id={field.name}
      label={field.label}
      type={field.type}
      defaultValue={form_data[field.name]}
      fullWidth
      variant="outlined"
      className={classes.fieldElement}
      onChange={handleFieldChange}
      disabled={is_submitting}
    />
  );
}

export default TextInput;
