import React from "react";
import { makeStyles } from "@mui/styles";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

const useStyles = makeStyles({
  fieldElement: {
    margin: "10px 0 10px 0 !important",
  },
});

const Dropdown = ({field, index, form_data, handleFieldChange, is_submitting}) => {
  const classes = useStyles();

  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} className={classes.fieldElement} fullWidth>
      <InputLabel id="demo-simple-select-label">{field.label}</InputLabel>
      <Select
        id={field.name}
        name={field.name}
        label={field.label}
        onChange={handleFieldChange}
        displayEmpty
        autoFocus={index === 0 ? true : false}
        defaultValue={form_data[field.name]}
        variant="outlined"
        disabled={is_submitting}
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {field.options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default Dropdown;
