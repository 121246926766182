import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

const useStyles = makeStyles({
  searchContainer: {
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 20,
    marginTop: 20,
    border: "1px solid #ccc",
    padding: 20,
    borderRadius: 7,
  },
  elementsContainer: {
    display: "flex",
    alignItems: "left",
  },
  header: {
    margin: "0 0 10px 0",
  },
  submitButton: {
    marginLeft: "10px!important"
  },
});

const BasicSearch = ({onSubmit}) => {
  const classes = useStyles();
  const [search, setSearch] = useState("");

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13 || event.key === "Enter") {
      onSubmit(search);
    }
  };

  const handleClickSubmit = (event) => {
    onSubmit(search);
  };

  return (
    <>
      <div className={classes.searchContainer}>
        <h5 className={classes.header}>Basic Search</h5>
        <div className={classes.elementsContainer}>
          <TextField
            label="Search"
            value={search}
            onChange={handleSearchChange}
            onKeyDown={handleKeyDown}
            size="small"
            sx={{ width: "1000px" }}
          />
          <Button
            className={classes.submitButton}
            variant="contained"
            size="medium"
            color="primary"
            onClick={handleClickSubmit}
          >
            Search
          </Button>
        </div>
      </div>
    </>
  );
}

export default BasicSearch;
