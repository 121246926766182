import React from "react";
import TextInput from "./_TextInput";
import EmailInput from "./_EmailInput";
import Dropdown from "./_Dropdown";
import UnorderedList from "./_UnorderedList";
import FileUpload from "./_FileUpload";
import ImageViewer from "./_ImageViewer";

const TheInput = ({field, index, form_data, handleFieldChange, is_submitting}) => {

  if(field.type == 'email') {
    return (
      <EmailInput
        field={field}
        index={index}
        form_data={form_data}
        handleFieldChange={handleFieldChange}
        is_submitting={is_submitting}
      />
    );
  } else if(field.type == 'dropdown') {
    return (
      <Dropdown
        field={field}
        index={index}
        form_data={form_data}
        handleFieldChange={handleFieldChange}
        is_submitting={is_submitting}
      />
    );
  } else if(field.type == 'unordered_list') {
    return (
      <UnorderedList
        field={field}
        index={index}
        form_data={form_data}
        handleFieldChange={handleFieldChange}
        is_submitting={is_submitting}
      />
    );
  } else if(field.type == 'file_upload') {
    return (
      <FileUpload
        field={field}
        index={index}
        form_data={form_data}
        handleFieldChange={handleFieldChange}
        is_submitting={is_submitting}
      />
    );
  } else if(field.type == 'image_viewer') {
    return (
      <ImageViewer
        field={field}
        index={index}
        form_data={form_data}
        handleFieldChange={handleFieldChange}
        is_submitting={is_submitting}
      />
    );
  } else {
    return (
      <TextInput
        field={field}
        index={index}
        form_data={form_data}
        handleFieldChange={handleFieldChange}
        is_submitting={is_submitting}
      />
    );
  }

}

export default TheInput;
