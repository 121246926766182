import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import DataRow from "./_data_row";
// import { LIST_COLUMNS } from "./__def";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  table_loading: {
    opacity: 0.5,
  },
  loading_wrapper: {
    textAlign: "center",
    padding: 0,
    width: "100%",
  }
});

const DataTable = ({data, loading, onEditSuccess, API_URL, LINK_URL, LIST_COLUMNS, UPDATE_FIELDS, SHOW_FIELDS}) => {
  const classes = useStyles();
  const [records, setRecords] = useState([]);

  useEffect(() => {
    setRecords(data);
  }, [data]);

  const Loading = () => (<>
    <TableBody>
      <TableRow>
        <TableCell colSpan={LIST_COLUMNS.length}>
          <div className={classes.loading_wrapper}>
            Loading...
          </div>
        </TableCell>
      </TableRow>
    </TableBody>
  </>)

  const TableHeader = () => (<>
    <TableHead>
      <TableRow>
        {LIST_COLUMNS.map((col) => (
          <TableCell key={`thead_col_${col.id}`}>{col.label}</TableCell>
        ))}
      </TableRow>
    </TableHead>
  </>)

  return (
    <>
      <Table className={classes.table} aria-label="users table">
        <TableHeader />
        {loading && <Loading/>}
        <TableBody className={loading ? classes.table_loading : ''}>
          {records.map((record) => (
            <DataRow
              key={`data_row_${record.id}`}
              record={record}
              columns={LIST_COLUMNS}
              onEditSuccess={onEditSuccess}
              API_URL={API_URL}
              LINK_URL={LINK_URL}
              UPDATE_FIELDS={UPDATE_FIELDS}
              SHOW_FIELDS={SHOW_FIELDS}
            />
          ))}
        </TableBody>
      </Table>
    </>
  );
}

export default DataTable;
