import React, { useEffect, useState } from "react";
import ReactEcharts from "echarts-for-react";
import { DateRangePicker } from 'react-date-range';
import moment from "moment";
import {
  Box,
  Typography,
  Paper,
  Divider,
  Button,
  Select,
  MenuItem,
  OutlinedInput,
  InputLabel,
  FormControl,
  ListItemText,
  Checkbox,

} from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const ref_patron_types = [
  'Student',
  'Professor',
  'Staff',
  'Alumni',
  'Visitor',
];

const ref_courses = [
  'BS Computer Science',
  'BS Information Technology',
  'BS Information Systems',
  'BS Information Management',
  'BS Computer Engineering',
  'BS Electronics Engineering',
  'BS Electrical Engineering',
  'BS Mechanical Engineering',
  'BS Civil Engineering',
  'BS Industrial Engineering',
  'BS Chemical Engineering',
  'BS Architecture',
  'BS Interior Design',
  'BS Psychology',
  'BS Accountancy',
  'BS Accounting Technology',
  'BS Business Administration',
  'BS Entrepreneurship',
  'BS Legal Management',
  'BS Economics',
  'BS Communication',
  'BS Development Studies',
];

const ref_years = [
  '1st Year',
  '2nd Year',
  '3rd Year',
  '4th Year',
  '5th Year',
]

const option = {
  xAxis: {
    type: 'category',
    data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
  },
  yAxis: {
    type: 'value'
  },
  series: [
    {
      data: [120, 200, 150, 80, 70, 110, 130],
      type: 'bar'
    }
  ]
};

const Main = ({ school_id }) => {
  const [display_calendar, setDisplayCalendar] = useState(false);
  const [local_start_date, setLocalStartDate] = useState(moment().startOf('week').toDate());
  const [local_end_date, setLocalEndDate] = useState(moment().endOf('week').toDate());
  const [time_data, setTimeData] = useState({})
  const [patron_data, setPatronData] = useState({})
  const [course_data, setCourseData] = useState({})
  const [records, setRecords] = useState([]);

  const [patron_type, setPatronType] = useState([]);
  const [course, setCourse] = useState([]);
  const [year, setYear] = useState([]);

  const [target_dates, setTargetDates] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  });

  const handleChangePatronType = (event) => {
    const { target: { value } } = event;
    setPatronType(
      typeof value === 'string' ? value.split(',') : value,
    );
  }

  const handleChangeCourse = (event) => {
    const { target: { value } } = event;
    setCourse(
      typeof value === 'string' ? value.split(',') : value,
    );
  }

  const handleChangeYear = (event) => {
    const { target: { value } } = event;
    setYear(
      typeof value === 'string' ? value.split(',') : value,
    );
  }

  const rangeReadable = () => {
    var start_date_readable = moment(local_start_date).format('ddd, MMM Do');
    var end_date_readable = moment(local_end_date).format('ddd, MMM Do');

    if(start_date_readable == end_date_readable) {
      return start_date_readable;
    } else {
      return `${start_date_readable} - ${end_date_readable}`;
    }
  }

  const onDateRangeChange = (ranges) => {
    setTargetDates(ranges.selection);
    setLocalStartDate(ranges.selection.startDate);
    setLocalEndDate(ranges.selection.endDate);
    // console.log(ranges.selection);
    // onChange(ranges.selection);
  }

  const loadData = () => {
    console.log(moment(local_start_date).format('YYYY-MM-DD'));
    console.log(moment(local_end_date).format('YYYY-MM-DD'));
    fetch(`/library/students/data`, {
        method: "POST",
        headers: { "Content-Type": "application/json", },
        body: JSON.stringify({
          school_id: school_id,
          start_date: moment(local_start_date).format('YYYY-MM-DD'),
          end_date: moment(local_end_date).format('YYYY-MM-DD'),
        }),
      })
      .then((resp) => {
        if (resp.ok) { return resp.json(); }
        throw new Error("Network response was not ok.");
      })
      .then((resp) => {
        setRecords(resp.records)
        setTimeData(resp.time_data)
        setPatronData(resp.patron_data)
        setCourseData(resp.course_data)
      })
      .catch((error) => {
        console.log("Catch: ", error.message)
      });
  }

  return(
    <div>
    <Paper elevation={3} sx={{padding: "20px"}}>
      <Box>
        <h5>Report Filters</h5>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: "20px"}}>
        <Box>
          <FormControl sx={{ m: 1, width: 300, maxWidth: "100%" }}>
            <InputLabel id="patron-types-label">Patron Types</InputLabel>
            <Select
              labelId="patron-types-label"
              id="patron-types-checkbox"
              multiple
              value={patron_type}
              onChange={handleChangePatronType}
              input={<OutlinedInput label="Patron Types" />}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}
            >
              {ref_patron_types.map((i) => (
                <MenuItem key={i} value={i}>
                  <Checkbox checked={patron_type.indexOf(i) > -1} />
                  <ListItemText primary={i} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box>
          <FormControl sx={{ m: 1, width: 700, maxWidth: "100%" }}>
            <InputLabel id="courses-label">Courses</InputLabel>
            <Select
              labelId="courses-label"
              id="courses-checkbox"
              multiple
              value={course}
              onChange={handleChangeCourse}
              input={<OutlinedInput label="Courses" />}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}
            >
              {ref_courses.map((i) => (
                <MenuItem key={i} value={i}>
                  <Checkbox checked={course.indexOf(i) > -1} />
                  <ListItemText primary={i} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box>
          <FormControl sx={{ m: 1, width: 200, maxWidth: "100%" }}>
            <InputLabel id="years-label">Years</InputLabel>
            <Select
              labelId="years-label"
              id="patron-types-checkbox"
              multiple
              value={year}
              onChange={handleChangeYear}
              input={<OutlinedInput label="Years" />}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}
            >
              {ref_years.map((i) => (
                <MenuItem key={i} value={i}>
                  <Checkbox checked={year.indexOf(i) > -1} />
                  <ListItemText primary={i} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between'}}>
        
        {/* <Paper elevation={3}> */}
        {!display_calendar &&
        <Button
          size="large"
          variant="contained"
          onClick={() => setDisplayCalendar(true)}
        >({rangeReadable()})</Button>}

        {display_calendar && <Box>
          <Button
            size="large"
            variant="contained"
            onClick={() => setDisplayCalendar(false)}
          >Close Calendar</Button>
          <Divider />
          <DateRangePicker
            ranges={[target_dates]}
            onChange={onDateRangeChange}
            months={1}
            direction="vertical"
            scroll={{ enabled: true }}
          />
        </Box>}

        <Button
          size="small"
          variant="contained"
          onClick={loadData}
        >Apply Filter</Button>
      </Box>
      </Paper>


      <Box>
        <ReactEcharts option={time_data} />
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ flex: 1 }}>
            <ReactEcharts option={patron_data} />
          </Box>
          <Box sx={{ flex: 1 }}>
            <ReactEcharts option={course_data} />
          </Box>
        </Box>
      </Box>
      <Box sx={{marginBottom: 20}}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>Date</TableCell>
                <TableCell align="right">Time</TableCell>
                <TableCell align="right">Patron Type</TableCell>
                <TableCell align="right">Course</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {records.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.date}
                  </TableCell>
                  <TableCell align="right">{row.time}</TableCell>
                  <TableCell align="right">{row.patron_type}</TableCell>
                  <TableCell align="right">{row.course}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </div>
  )
}

export default Main;
