import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import DataTable from "./_data_table";
import DataPagination from "./_data_pagination";
import BasicSearch from "./_basic_search";
import AddData from "./_add_data";
import Notification from "./_notifications";

const useStyles = makeStyles({
  searchContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 20,
  }
});

const Template = ({API_URL, LINK_URL, ROWS_PER_PAGE, LIST_COLUMNS, CREATE_FIELDS, CREATE_FORM_DATA_DEFAULT, UPDATE_FIELDS, SHOW_FIELDS, VIEW_MODE, HIDE_SEARCH}) => {
  const classes = useStyles();
  const [records, setRecords] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [total_count, setTotalCount] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [show_notifications, setShowNotifications] = useState(false);
  const [notification, setNotification] = useState({message: '', type: ''});
  const [loading_data, setLoadingData] = useState(false);

  useEffect(() => {
    doSearch(search, page);
  }, [page]);

  const doSearch = (search) => {
    setLoadingData(true);
    fetch(`${API_URL}?page=${page}&per_page=${ROWS_PER_PAGE}&search=${search}`)
    .then((response) => response.json())
      .then((data) => {
        setRecords(data.records);
        setTotalCount(data.totalCount);
        setTotalPages(Math.ceil(data.totalCount / ROWS_PER_PAGE));
        setLoadingData(false);
      })
      .catch((error) => {
        setLoadingData(false);
        console.error(error);
      });
  };

  const handleSearchSubmit = (search) => {
    setSearch(search);
    if(page == 1) {
      doSearch(search);
    } else {
      setPage(1);
    }
  };

  const handlePageChange = (value) => {
    setPage(value);
  };

  const handleSuccessAddNewRecord = (value) => {
    setNotification({message: 'Record added successfully', type: 'success'})
    setShowNotifications(true);
    setTimeout(() => {
      setShowNotifications(false);
    }, 3000);
    doSearch('', 1);
  };

  const handleSuccessEditRecord = (value) => {
    setNotification({message: 'Record updated successfully', type: 'success'})
    setShowNotifications(true);
    setTimeout(() => {
      setShowNotifications(false);
    }, 3000);
    doSearch(search, page);
  };

  return (
    <>
      {!VIEW_MODE && CREATE_FIELDS.length > 0 && <AddData
        onSuccess={handleSuccessAddNewRecord}
        API_URL={API_URL}
        CREATE_FIELDS={CREATE_FIELDS}
        CREATE_FORM_DATA_DEFAULT={CREATE_FORM_DATA_DEFAULT}
      />}
      {!HIDE_SEARCH && <BasicSearch onSubmit={handleSearchSubmit} />}
      {VIEW_MODE && total_count > 0 &&
        <div class="mb-2">Total Results: {total_count}</div>
      }
      <TableContainer component={Paper}>
        <DataTable
          data={records}
          loading={loading_data}
          onEditSuccess={handleSuccessEditRecord}
          API_URL={API_URL}
          LINK_URL={LINK_URL}
          LIST_COLUMNS={LIST_COLUMNS}
          UPDATE_FIELDS={UPDATE_FIELDS}
          SHOW_FIELDS={SHOW_FIELDS}
        />
        <DataPagination total_pages={totalPages} page={page} onChange={handlePageChange} />
      </TableContainer>
      {show_notifications && <Notification message={notification.message} type={notification.type} />}
    </>
  );
}

export default Template;
