import React from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  fieldElement: {
    margin: "10px 0 10px 0 !important",
  },
  image: {
    width: '100%',
    border: "1px solid #ccc"
  }
});

const ImageViewer = ({field, index, form_data}) => {
  const classes = useStyles();

  return (
    <div>
      <img src={form_data[field.name]} className={classes.image} />
    </div>
  );

}

export default ImageViewer;
