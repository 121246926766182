export const API_URL = "/api/v1/schools/:school_id/school_libraries";

export const ROWS_PER_PAGE = 10;

export const CREATE_FIELDS = []

export const CREATE_FORM_DATA_DEFAULT = {
  first_name: "",
  last_name: "",
  email: "",
};

export const LIST_COLUMNS = [
  { id: "id", label: "ID" },
  { id: "name", label: "Name" },
  { id: "youtube_url", label: "Youtube URL" },
  { id: "local_video_path", label: "Local Video Path" },
  { id: "actions", label: "Actions" },
]

export const UPDATE_FIELDS = [
  {name: "name", label: "Name", type: "text"},
  {name: "youtube_url", label: "Youtube URL", type: "text"},
  {name: "local_video_path", label: "Local Video Path", type: "text"},
  {name: "logo", label: "Logo", type: "file_upload", url: `${API_URL}/:id/upload_logo`},
];

export const SHOW_FIELDS = [
  { name: "name", label: "Name", type: "text" },
  { name: "youtube_url", label: "Youtube URL", type: "text" },
  { name: "local_video_path", label: "Local Video Path", type: "text" },
  { name: "logo", label: "Logo", type: "image_viewer" },
]
