import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TheInput from './_field_components/_TheInput';

const useStyles = makeStyles({
  fieldElement: {
    margin: "10px 0 10px 0 !important",
  },
  dialogActions: {
    padding: "0 30px 30px 30px !important",
  },
  elementsContainer: {
    display: "flex",
    alignItems: "left",
  },
  header: {
    margin: "0 0 20px 0 !important",
  },
  submitButton: {
    marginLeft: "10px!important"
  },
  status_wrapper: {
    padding: "0 0 10px 0 !important",
    textAlign: "center",
    color: '#444'
  },
});

const InfoData = ({record, onClose, onSuccess, API_URL, SHOW_FIELDS}) => {
  const classes = useStyles();
  const [show_form, setShowForm] = useState(true);
  const [form_data, setFormData] = useState({...record});
  const [is_ready, setIsReady] = useState(false);

  useEffect(() => {
    loadFreshData()
  }, [record]);

  const loadFreshData = () => {
    fetch(`${API_URL}/${record.id}`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Something went wrong");
        }
      })
      .then((json) => {
        setFormData(json);
        setIsReady(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClose = (e, reason) => {
    if(reason === "backdropClick") {
      // do nothing
    } else {
      setShowForm(false);
      onClose();
    }
  };

  return (
    <>
      <Dialog open={show_form} onClose={handleClose} disableEscapeKeyDown>
        {/* <DialogTitle>Edit Record</DialogTitle> */}
        <DialogContent>
          {!is_ready && <div className={classes.status_wrapper}>Loading...</div>}
          {is_ready && SHOW_FIELDS.map((field, index) => (
            <TheInput
              field={field}
              form_data={form_data}
              index={index}
            />
          ))}
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default InfoData;
