import React from "react";
import { makeStyles } from "@mui/styles";
import Pagination from "@mui/material/Pagination";

const useStyles = makeStyles({
  paginationContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 20,
  },
});

const DataPagination = ({total_pages, page, onChange}) => {
  const classes = useStyles();

  const onChangePage = (event, value) => {
    onChange(value);
  };

  return (
    <div className={classes.paginationContainer}>
      <Pagination count={total_pages} page={page} onChange={onChangePage} />
    </div>
  );
}

export default DataPagination;
