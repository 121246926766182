import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import TheInput from './_field_components/_TheInput';

const useStyles = makeStyles({
  hiddenFormContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "right",
  },
  fieldElement: {
    margin: "10px 0 10px 0 !important",
  },
  dialogActions: {
    padding: "0 30px 30px 30px !important",
  },
  elementsContainer: {
    display: "flex",
    alignItems: "left",
  },
  header: {
    margin: "0 0 20px 0 !important",
  },
  submitButton: {
    marginLeft: "10px!important"
  },
  status_wrapper: {
    padding: "0 0 10px 0 !important",
    textAlign: "center",
    color: '#444'
  },
});

const AddData = ({onSuccess, API_URL, CREATE_FIELDS, CREATE_FORM_DATA_DEFAULT}) => {
  const classes = useStyles();
  const [show_form, setShowForm] = useState(false);
  const [form_data, setFormData] = useState({...CREATE_FORM_DATA_DEFAULT});
  const [show_error, setShowError] = useState(false);
  const [show_errors, setShowErrors] = useState(false);
  const [errors, setErrors] = useState([]);
  const [is_submitting, setIsSubmitting] = useState(false);

  const handleSubmit = () => {
    setIsSubmitting(true);
    fetch(`${API_URL}`, {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify(form_data),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          setShowError(true);
          throw new Error("Something went wrong");
        }
      })
      .then((json) => {
        if(json.errors) {
          setShowErrors(true);
          setErrors(json.errors);
          setIsSubmitting(false);
        } else {
          setShowError(false);
          setShowForm(false);
          setFormData({...CREATE_FORM_DATA_DEFAULT});
          onSuccess("Successfully added new record");
          setIsSubmitting(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setIsSubmitting(false);
      });
  };

  const handleClose = (e, reason) => {
    if(reason === "backdropClick") {
      // do nothing
    } else {
      setFormData({...CREATE_FORM_DATA_DEFAULT});
      setShowForm(false);
      setShowError(false);
      setShowErrors(false);
      setErrors([]);
    }
  };

  const handleFieldChange = (event) => {
    if(event.target.id !== undefined) {
      form_data[event.target.id] = event.target.value;
    } else {
      form_data[event.target.name] = event.target.value;
    }
    setFormData(form_data);
  };

  return (
    <>
      <div className={classes.hiddenFormContainer}>
        {/* {!show_form && ( */}
          <Button variant="contained" color="warning" onClick={() => setShowForm(true)}>
            Add New Record
          </Button>
        {/* )} */}
      </div>

      <Dialog open={show_form} onClose={handleClose} disableEscapeKeyDown>
        <DialogTitle>Add New Record</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please make sure you have all the information you need before adding a new record.
            {show_errors && (
              <Alert severity="error">
                {errors.map((error) => (
                  <div>{error}</div>
                ))}
              </Alert>
            )}
          </DialogContentText>
          <hr/>
          {is_submitting && <div className={classes.status_wrapper}>Submitting...</div>}
          {CREATE_FIELDS.map((field, index) => (
            <TheInput
              field={field}
              form_data={form_data}
              handleFieldChange={handleFieldChange}
              is_submitting={is_submitting}
              index={index}
            />
          ))}
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit} color="primary" variant="contained" disabled={is_submitting}>
            {is_submitting ? 'Submitting...' : 'Submit'}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={show_error}
        onClose={() => setShowError(false)}
      >
        <Alert severity="error">Something is not right, please try again...</Alert>
      </Snackbar>
    </>
  );
}

export default AddData;
