import React from "react";
import { makeStyles } from "@mui/styles";
import FormControl from '@mui/material/FormControl';

const useStyles = makeStyles({
  fieldElement: {
    margin: "10px 0 10px 0 !important",
  },
});

const UnorderedList = ({field, index, form_data}) => {
  const classes = useStyles();

  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} className={classes.fieldElement} fullWidth>
      {form_data[field.name].map((data) => {
        return(
          <>
            <h5>{data[field.label]}</h5>
            <p>{data[field.value]}</p>
          </>
        )
      })}
    </FormControl>
  );
}

export default UnorderedList;
