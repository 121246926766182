import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import EditData from "./_edit_data";
import InfoData from "./_info_data";

const useStyles = makeStyles({
});

const DataRow = ({record, columns, onEditSuccess, API_URL, LINK_URL, UPDATE_FIELDS, SHOW_FIELDS}) => {
  const classes = useStyles();
  const [local_record, setLocalRecord] = useState({...record});
  const [show_edit_form, setShowEditForm] = useState(false);
  const [show_info, setShowInfo] = useState(false);

  useEffect(() => {
    setLocalRecord(record);
  }, [record]);

  const handleClickShow = (e, record) => {
    setShowInfo(true)
  }

  const handleClickEdit = (e, record) => {
    setShowEditForm(true);
  }

  const dataColumns = (record, columns) => (<>
    {columns.map((col) => {
      if(col.id === "actions") {
        return <ActionsColumn
          record={record}
          show_button={SHOW_FIELDS.length > 0}
          edit_button={UPDATE_FIELDS.length > 0} />
      } else if(col.type && col.type === "link") {
        return(
          <TableCell>
            <a href={`${LINK_URL}/${record.id}`} target="_blank">{record[col.id]}</a>
          </TableCell>
        )
      } else {
        return <TableCell>{record[col.id]}</TableCell>
      }
    })}
  </>)

  const ActionsColumn = ({record, show_button, edit_button}) => (<>
    <TableCell>
      <Stack direction="row" spacing={1}>
        {show_button && <ShowButton record={record} onClick={handleClickShow} />}
        {edit_button && <EditButton record={record} onClick={handleClickEdit} />}
      </Stack>
    </TableCell>
  </>)

  const ShowButton = ({record, onClick}) => (<>
    <Button variant="contained" color="primary" onClick={(e) => onClick(e, record)}>
      Show
    </Button>
  </>)

  const EditButton = ({record, onClick}) => (<>
    <Button variant="contained" color="warning" onClick={(e) => onClick(e, record)}>
      Edit
    </Button>
  </>)

  return (
    <>
      <TableRow key={local_record.id}>
        {dataColumns(local_record, columns)}
      </TableRow>
      {show_edit_form && 
        <EditData
          record={local_record}
          onClose={() => setShowEditForm(false)}
          onSuccess={(message) => onEditSuccess(message)}
          API_URL={API_URL}
          UPDATE_FIELDS={UPDATE_FIELDS}
        />
      }
      {show_info && 
        <InfoData
          record={local_record}
          onClose={() => setShowInfo(false)}
          API_URL={API_URL}
          SHOW_FIELDS={SHOW_FIELDS}
        />
      }
    </>
  );
}

export default DataRow;
