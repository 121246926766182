import React from "react";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const Notification = ({message, type}) => {

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      open={true}
    >
      <Alert severity={type}>{message}</Alert>
    </Snackbar>
  );
}

export default Notification;
