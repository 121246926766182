export const API_URL = "/api/v1/school_students";

export const ROWS_PER_PAGE = 10;

export const CREATE_FIELDS = [
  {name: "student_number", label: "Student Number", type: "text"},
  {name: "first_name", label: "First Name", type: "text"},
  {name: "middle_name", label: "Middle Name", type: "text"},
  {name: "last_name", label: "Last Name", type: "text"},
  {name: "email", label: "Email", type: "text"},
  {name: "rfid_code", label: "RFID Code", type: "text"},
  {name: "student_type", label: "Student Type", type: "dropdown", options: [
    {value: "daycare", label: "Daycare"},
    {value: "elementary", label: "Elementary"},
    {value: "highschool", label: "High School"},
    {value: "seniorhigh", label: "Senior High"},
    {value: "college", label: "College"},
    {value: "master", label: "Master"},
    {value: "doctorate", label: "Doctorate"},
  ]},
  {name: "course", label: "Course", type: "text"},
  {name: "year", label: "Year", type: "text"},
  {name: "section", label: "Section", type: "text"},
]

export const CREATE_FORM_DATA_DEFAULT = {
  student_number: "",
  first_name: "",
  middle_name: "",
  last_name: "",
  email: "",
  rfid_code: "",
  student_type: "college",
  course: "",
  year: "",
  section: "",
};

export const LIST_COLUMNS = [
  { id: "student_number", label: "Student Number"},
  { id: "last_name",      label: "Last Name"},
  { id: "first_name",     label: "First Name"},
  { id: "middle_name",    label: "Middle Name"},
  { id: "student_type",   label: "Student Type"},
  { id: "course",         label: "Course"},
  { id: "actions",        label: "Actions" },
]

export const UPDATE_FIELDS = [
  {name: "student_number", label: "Student Number", type: "text"},
  {name: "first_name", label: "First Name", type: "text"},
  {name: "middle_name", label: "Middle Name", type: "text"},
  {name: "last_name", label: "Last Name", type: "text"},
  {name: "email", label: "Email", type: "text"},
  {name: "rfid_code", label: "RFID Code", type: "text"},
  {name: "student_type", label: "Student Type", type: "dropdown", options: [
    {value: "daycare", label: "Daycare"},
    {value: "elementary", label: "Elementary"},
    {value: "highschool", label: "High School"},
    {value: "seniorhigh", label: "Senior High"},
    {value: "college", label: "College"},
    {value: "master", label: "Master"},
    {value: "doctorate", label: "Doctorate"},
  ]},
  {name: "course", label: "Course", type: "text"},
  {name: "year", label: "Year", type: "text"},
  {name: "section", label: "Section", type: "text"},
  {name: "image", label: "Image", type: "file_upload", url: `${API_URL}/:id/upload_image`},
];

export const SHOW_FIELDS = [
  {name: "id", label: "ID", type: "text"},
  {name: "student_number", label: "Student Number", type: "text"},
  {name: "first_name", label: "First Name", type: "text"},
  {name: "middle_name", label: "Middle Name", type: "text"},
  {name: "last_name", label: "Last Name", type: "text"},
  {name: "email", label: "Email", type: "text"},
  {name: "rfid_code", label: "RFID Code", type: "text"},
  {name: "student_type", label: "Student Type", type: "text" },
  {name: "course", label: "Course", type: "text"},
  {name: "year", label: "Year", type: "text"},
  {name: "section", label: "Section", type: "text"},
  {name: "image", label: "Image", type: "image_viewer"},
];
