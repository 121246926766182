export const API_URL = "/api/v1/schools/:school_id/school_libraries/:school_library_id/school_library_featured_books";

export const ROWS_PER_PAGE = 10;

export const CREATE_FIELDS = [
  {name: "title", label: "Title", type: "text"},
  {name: "status", label: "Status", type: "text"},
]

export const CREATE_FORM_DATA_DEFAULT = {
  title: "",
  status: "active",
};

export const LIST_COLUMNS = [
  { id: "title",    label: "Title"},
  { id: "status",   label: "Status"},
  { id: "actions",  label: "Actions" },
]

export const UPDATE_FIELDS = [
  {name: "title", label: "Title", type: "text"},
  {name: "status", label: "Status", type: "text"},
  {name: "image", label: "Image", type: "file_upload", url: `${API_URL}/:id/upload_image`},
];

export const SHOW_FIELDS = [
  {name: "id", label: "ID", type: "text"},
  {name: "title", label: "Title", type: "text"},
  {name: "status", label: "Status", type: "text"},
  {name: "image", label: "Image", type: "image_viewer"},
];

// { id: "student_number", label: "Student Number"},
// { id: "last_name",      label: "Last Name"},
// { id: "first_name",     label: "First Name"},
// { id: "middle_name",    label: "Middle Name"},
// { id: "student_type",   label: "Student Type"},
// { id: "course",         label: "Course"},
