import React from "react";
import Template from '../_common/Template';
import {
  API_URL,
  ROWS_PER_PAGE,
  LIST_COLUMNS,
  CREATE_FIELDS,
  CREATE_FORM_DATA_DEFAULT,
  UPDATE_FIELDS,
  SHOW_FIELDS,
} from "./__def";

const Main = ({school_id, school_library_id}) => {
  return (
    <Template
      API_URL={API_URL.replace(":school_id", school_id).replace(":school_library_id", school_library_id)}
      ROWS_PER_PAGE={ROWS_PER_PAGE}
      LIST_COLUMNS={LIST_COLUMNS}
      CREATE_FIELDS={CREATE_FIELDS}
      CREATE_FORM_DATA_DEFAULT={CREATE_FORM_DATA_DEFAULT}
      UPDATE_FIELDS={UPDATE_FIELDS.map(i => {
        if (i.url != undefined) {
          i.url = i.url.replace(":school_id", school_id).replace(":school_library_id", school_library_id);
        }
        return i;
      })}
      SHOW_FIELDS={SHOW_FIELDS}
    />
  )
}

export default Main;
